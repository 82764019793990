import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import blogStyles from "./blog.module.scss" 
import Head from "../components/head"
import higiene from "../images/higiene.jpg"
import bebida from "../images/bebida.jpg"
import pet from "../images/pet.jpeg"
import bazar from "../images/bazar.jpg"
import limpeza from "../images/limpeza.jpg"
import mercearia from "../images/mercearia.jpg"

const BlogPage = () => {
  
  return (
    <Layout>
      <Head title="Produtos" />
      <h1>PRODUCTS</h1>
      <div className={blogStyles.grid}>
        <div className={blogStyles.card}>
          <figure>
          <Link to="/higiene">
              <img className={blogStyles.image} src={higiene} alt="higiene" />
          </Link>
          <h2>Hygiene</h2>
          </figure>
        </div>
        <div className={blogStyles.card}>  
          <figure>
          <Link to="/bebidas">
              <img className={blogStyles.image} src={bebida} alt="bebida" />
          </Link>
            <h2>Beverages</h2>
          </figure>
        </div>
        <div className={blogStyles.card}>
          <figure>
          <Link to="/limpeza">
            <img className={blogStyles.image} src={limpeza} alt="limpeza" />
          </Link>
            <h2>Cleaning</h2>
          </figure>
        </div>
        <div className={blogStyles.card}>
          <figure>
          <Link to="/pet">
            <img className={blogStyles.image} src={pet} alt="pet" />
          </Link>
            <h2>Pet</h2>
          </figure>
        </div>
        <div className={blogStyles.card}>
          <figure>
          <Link to="/mercearia">
            <img className={blogStyles.image} src={mercearia} alt="mercearia" />
          </Link>
            <h2>Groceries</h2>
          </figure>
        </div>
        <div className={blogStyles.card}>
          <figure>
          <Link to="/bazar">
            <img className={blogStyles.image} src={bazar} alt="bazar" />
          </Link>
            <h2>Bazaar</h2>
          </figure>
        </div>


      </div>
      

    </Layout>
  )
}

export default BlogPage

